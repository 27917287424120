import React from 'react'
import { Responsive } from 'semantic-ui-react'
import DesktopMenu from './desktop'
import MobileMenu from './mobile'

const isBrowser = () => typeof window !== 'undefined'
const getWidth = () => (isBrowser() ? window.innerWidth : 1000)

const Header = props => (
  <React.Fragment>
    <DesktopMenu />
    <MobileMenu />
  </React.Fragment>
)

export default Header
