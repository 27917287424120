import React from 'react'
import { Image } from 'semantic-ui-react'
import logo from '../../images/logo_beaumel_blanc.png'

/* logo 160px on mobile */

const Logo = () => (
  <Image
    size="medium"
    src={logo}
    style={{ marginRight: '1.5em', width: '200px' }}
    alt="Chateau de Beaumel"
  />
)

export default Logo
