import React from 'react'
import { Link } from 'gatsby'
import {
  Segment,
  Container,
  Grid,
  List,
  Header,
  Icon,
  Divider,
} from 'semantic-ui-react'
import { FormattedMessage } from 'react-intl'

const Footer = () => (
  <Segment
    vertical
    style={{
      padding: '4em 0 0 0',
      marginTop: '3em',
      borderTop: '1px solid #f2f2f2',
      backgroundColor: 'rgba(42, 38, 38, 1)',
      color: 'white',
    }}
  >
    <Container>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column width={8}>
            <Header as="h4" style={{ color: 'white' }}>
              Château de Beaumel
            </Header>
            <p>
              <FormattedMessage id="openingTimes" />
            </p>
            <div>
              <FormattedMessage id="gpsCoordinates" /> :{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                alt="Google Maps"
                href="https://www.google.com/maps/place/CHATEAU+DE+BEAUMEL/@43.3483271,6.2973593,15z/data=!4m5!3m4!1s0x0:0xdf2d90f6ec7a41c8!8m2!3d43.3483271!4d6.3061141?authuser=1"
              >
                43°20'59.8"N 6°18'16.6"E
              </a>
            </div>
          </Grid.Column>
          <Grid.Column width={4}>
            <div>Quartier Beaumet</div>
            <div>F-83590 Gonfaron</div>
            <div>Tél : +33 (0)4 98 05 21 00</div>
            <div>Fax : +33 (0)4 94 78 27 40</div>
          </Grid.Column>
          <Grid.Column width={4}>
            <List>
              <List.Item>
                <List.Header size="large">
                  <a href="mailto:info@chateaudebeaumel.fr" alt="email link">
                    <p>
                      <Icon name="mail" inverted /> info@chateaudebeaumel.fr
                    </p>
                  </a>
                </List.Header>
              </List.Item>
              <List.Item>
                <List.Header size="large">
                  <a
                    href="https://www.facebook.com/www.chateaudebeaumel.fr/"
                    alt="facebook link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p>
                      <Icon name="facebook" inverted /> Facebook
                    </p>
                  </a>
                </List.Header>
              </List.Item>
              <br />
              <List.Item>
                <List.Header>
                  <Link to="/cgv/">
                    <p>
                      <Icon name="handshake" inverted />{' '}
                      <FormattedMessage id="termsAndConditions" />
                    </p>
                  </Link>
                </List.Header>
              </List.Item>
            </List>
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row centered>
          <p
            style={{
              marginTop: '40px',
              marginBottom: '30px',
            }}
          >
            L'ABUS D'ALCOOL EST DANGEREUX POUR LA SANTÉ - CONSOMMEZ AVEC
            MODÉRATION
            <br />
            La vente d'alcool à des mineurs de moins de 18 ans est interdite. En
            accédant à nos offres, vous déclarez avoir 18 ans révolus.
          </p>
        </Grid.Row>
      </Grid>
    </Container>
  </Segment>
)

export default Footer
