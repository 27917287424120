import React from 'react'
import { Dropdown } from 'semantic-ui-react'

class SelectLanguage extends React.Component {
  render() {
    const { locale, updateLocale } = this.props
    return (
      <Dropdown 
        options={[
          {text: 'EN', value: 'en'},
          {text: 'FR', value:'fr'},
        ]}
        onChange={(e, data) => {
          updateLocale(data.value)
        }}
        value={locale}
      />
    )
  }
}

export default SelectLanguage
