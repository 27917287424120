import React from 'react'
import { Link } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import { Icon } from 'semantic-ui-react'
import { ContextConsumer } from '../Context'
import { Media } from '../layout'

const ShoppingCartIcon = () => (
  <ContextConsumer>
    {({ set, store }) => (
      <React.Fragment>
        <Link
          to="/cart"
          onClick={() => {
            set({ isCartOpen: true })
          }}
          style={{ color: 'white' }}
        >
          <Media greaterThanOrEqual="tablet">
            <FormattedMessage id="panier" />({store.cartCount})
          </Media>
          <Media lessThan="tablet">
            <Icon name="cart" />({store.cartCount})
          </Media>
        </Link>
      </React.Fragment>
    )}
  </ContextConsumer>
)

export default ShoppingCartIcon
