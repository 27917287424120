const messages = {
  en: {
    domaine: 'The Estate',
    terroir: 'The Terroir',
    wines: 'The Wines',
    equipe: 'The Team',
    medailles: 'Awards',
    boutique: 'Shop',
    panier: 'My Cart',
    rouge: 'The Reds',
    rose: 'The Rosés',
    blanc: 'The Whites',
    Rouges: 'The Reds',
    Rosés: 'The Rosés',
    Blancs: 'The Whites',
    // home page
    homeHeroTitle1: 'Authenticity',
    homeHeroSubtitle1: 'In the heart of the Var country, a small piece of heaven preserving the passion for winemaking for over a century',
    homeHeroTitle2: 'Nature spirit',
    homeHeroSubtitle2: 'Facing the Maures mountains, the rebirth of a vineyard, rich of the most iconic grapes of the AOP Côtes de Provence...',
    homeHeroTitle3: 'The soul of Provence',
    homeHeroSubtitle3: 'Wines infused by the Provence sun and a breath of Mediterranean winds...',
    homeTitle1: 'A passion in winemaking',
    homeParagraph1: 'Embracing the life of winemakers meant spicing up our life with a touch of crazy, a lot of love, and receiving unparalleled satisfaction. Because it is crazy to choose a life made of work and patience, of year-round labour, which can be ruined in an instant on a whim of the skies, or the subtle chemistry of vinification. It is also a lot of love, or rather, many loves: love for wine, and love for terroir, but also and mostly love for the Midi, the coastal part of Provence where sea, sun and sky meet the rocks and the trees, and the country welcomes you with open arms to celebrate its beauty. So, of course, satisfactions abound, but none are as vivid as the ones experienced when you taste the fruit of your hard work, whether when, at the time of harvest, you pick a grape while walking through the vines or upon the first glass coming out of the vats, rich of promises of wines to come. All this folly, this passion, these pleasures have been put in our bottles, and we can only hope that, upon uncorking them, they will enchant you as they have bewitched us.',
    homeTitle2: 'The Wines of Château de Beaumel',
    homeParagraph2: 'Most of our vineyard’s wines belong to the Cotes de Provence Appellation label. Since its inception in 1977, this Protected Designation of Origin (or AOP) has consistently demonstrated its uniqueness, combining conviviality and refinement, tradition and modernity. Today, rosés bearing its seal are world-wide references, its white wines become increasingly popular, while its sweet reds surprise and charm the tongue. Chateau de Beaumel offers this palette of colors and taste to your table.',
    homeRecompenseTitle: 'Recognition',
    homeRecompenseText: 'In May 2016, Hachette, the French reference of oenoligy guides, issued its first guide dedicated to rosés, in which our very own Cuvée Diamant appears, awarded with a first star rating and the following comment: “A Grenache and cinsault rosé with pretty amber glints, with a strongly focus on fruits since olfaction: peach, blackberry, lychee…Its palate offers volume, consistence and body balanced with the intensity of exotic fruit. A wine for the table.”',
    // domaine page
    domaineHeroTitle1: 'Under the charm',
    domaineHeroSubtitle1: 'Hidden in the shade of hundred years old trees, a carefully restored 17th century country house...',
    domaineHeroTitle2: 'A moment of peace and quiet',
    domaineHeroSubtitle2: 'Escape the city stress and noise to enjoy a silence only disturbed by cicadas and wind in the pine trees...',
    domaineHeroTitle3: 'Conviviality',
    domaineHeroSubtitle3: 'Discover all our wines in the wine-tasting cellar, open all year long, and share a drink with your friends...',
    domaineTitle1: 'In the heart of Provence',
    domaineParagraph1: 'The domain of Chateau de Beaumel can be found at the outskirts of the Gonfaron village, surrounded by the Maures moutains, and hides at the heart of over 30 hectares of vines a beautiful bastide.',
    domaineParagraph2: 'The winemaking tradition of the domain dates back a century, but truly blossomed in the nineties. At that time, the Chateau, known then as "Domaine de Beaumet", contributed to the development of the growing reputation of Cotes de Provence wines, bringing to the table its savoir-faire and character.',
    domaineParagraph3: 'Paradoxically for a country better known for its rosés, the domain earned its first awards for its white and red wines, at the Paris and Macon salons, the two most prestigious wine competitions in France. These medals came as a testament of the quality of its terroir and winemaking expertise. After these first accolades, the prestige of the name kept growing, reinforced by an increasing record, and led to a renewed investment in its means of production at the turn of the millennium, by renovating the caves and restructuring the estate. This pursuit of excellence endures with the current owners of the vineyard, the Caille family.',
    domaineTitle2: 'Love at first sight',
    domaineParagraph4: 'Daniel was the first to dream of winemaking. A few years ago, his farmer roots, inherited from his grand-parents, awoke and stroke a cord in this engineer’s heart. At the dawn of his sixties, and after having spent most of his professional life in health and environment sectors, this Polytechnique alumnus decided to take on a new challenge by adding a new feather to his cap: winemaker. Before they set off for this new adventure with the support of his wife Brigitte, she insists on one condition: for this true – in blood as well as temperament - Mediterranean woman, this journey “back to the roots” can only be considered under the sun of the Midi. Their search for the perfect site in Provence lead them to the gates of Beaumet, where they realised immediately that the quest was over.',
    domaineParagraph5: 'Could it ever be otherwise? How could anyone resist the charm of this beautiful estate, with walls dyed by faded sunlight, emerging in a sea of nature where green and crimson intertwine, graced with a century-strong vineyard? The potential for development of that estate, barely tapped into in years past, is rich of the best grapes Provence has to offer, essentially AOP Côtes de Provence: syrah, cinsault, grenache, mourvèdre, cabernet-sauvignon, rolle… All this variety allows, thanks to the art of assemblage, to bring to the senses the perfume of Provence and the harmony of Chateau de Beaumel.',
    // home wine items
    rougeDescription: 'Wines inscribed in the great tradition of Provence reds: a nose of fruits and berries, a silk and smooth palate underlying the strength of tannins, to be enjoyed immediately or preserved in cave for a couple of years.',
    roseDescription: 'Château de Beaumel brings you the trademarked crisp, crystalline rosés of prestigious Provence wines, with typical rich flavours and a sweet palate.',
    blancDescription: 'Beautiful expressions of rolle grapes, one of the noblest vines of Provence, a smooth nose and a strong palate for a wine proudly asserting its character for aperitif as well as dinner.',
    // terroir page
    terroirHeroTitle1: 'A taste of terroir',
    terroirHeroSubtitle1: 'A domain of over 30 hectares cultivated with care and balance...',
    terroirHeroTitle2: 'The roots of good wine',
    terroirHeroSubtitle2: 'A rich and rocky ground, a clay and limestone soil from which the vines draw their strength...',
    terroirHeroTitle3: 'Southern accent',
    terroirHeroSubtitle3: 'Grenache, cinsault, sirah and rolle for archetypally Mediterranean wines...',
    terroirTitle1: 'From hearth to wine',
    terroirParagraph1: 'Any winemaker knows the following truth : no matter one’s expertise, no matter the tools, no good wine can be produced without great grapes, and no great grape can be found without help from Mother Nature. Several factors have to be taken into account, the first of which if of course the weather, to which we owe the famous "millesime effect". The Provence country is thankfully blessed with a remarkable climate, free from cold and heat waves alike, and its generous sun and wide blue skies are renowned worldwide, as can evidence the millions of tourists visiting every year.',
    terroirTitle2: 'A matter of soil',
    terroirParagraph2: 'In addition to a benevolent weather, a generous ground is indispensable. The vines of Chateau de Beaumel grow on clay and limestone plains. The clay allows the ground to retain humidity necessary to help the plant withstand the warmest times of summers, and continue its maturation despite the vaunted sun. It also favorably stimulates the tannins production, one of wine-making\'s most important element. This natural substance, once gone through the expert hands of a maitre de chais, is indeed a key ingredient to bring wines their specific structure and character. Limestone, for its part, confers acidity to the grapes, and develops their perfume, and the combination of these two effects is ideal for sharp and sweet rosés.',
    terroirParagraph3: 'Another characteristic of the fields of Beaumel is their rocky ground, an undeniable asset. The presence of rocks in the soil is indeed a very efficient agent allowing the hearth to breathe and be drained, storing and releasing heat (a phenomenon allowing a greater consistency in grapes maturation in summer) and deeper roots for the vines. These deep roots are in turn able to extract more easily trace elements hidden underneath the surface, and upon harvest, the work of the sun finally done, the aromatic potential of the fruit is all the greater for it. And that is before any of our team\'s care and efforts, dedicated to a natural and biological approach, have taken place...',
    terroirTitle3: 'Quality vines',
    terroirParagraph4: 'Taste of wine obviously depends on the plants from which it is drawn. Age matters, in this aspect; indeed, after they have reached their twentieth anniversary, vine stocks produce less, but produce better, bringing body and perfume to the wine. So, while renewing regularly the vines to ensure its vitality, Chateau de Beaumel looks carefully after its oldest assets: thirty-year old Grenache, the jewel of the Diamant cuvee, and cabernet-sauvignon ten years older are the pride of the estate, and combine in the renowned Grande Reserve vintage.',
    terroirParagraph5: 'The variety of grapes is also decisive. The estate has diversified its plantation to allow for the production of high quality red, white and rosés alike. One can find there Grenache and cinsault, the two inseparable parts of the blend that makes Provence rosés so unique. Semillon and rolle, also known as vermentino on this side of the Mediterranean Sea, have found there prosperous ground and give rich white wines. Finally, red wines enjoy the contributions of shiraz and cabernet-sauvignon, but only for Cotes de Provence AOP. Because Chateau de Beaumel made an exception to the tradition Provence winemaking, and fell under the charm of a typical Bordeaux grape.. the merlot. These vines produce a country wine 100% merlot, combining a playful fruitiness to its delicate finish.',
    // equipe page
    equipeTitle1: 'The value of teamwork',
    equipeParagraph1: '“Experience bears fruit! Mine has taught me that the success of any enterprise always comes from the strength of a collective effort. Of course, this effort has to be canalised by a leader who will provide the impulsion and direction, determine the goals and allow the necessary resources to reach them. But this drive can run out of steam if it is not sustained on the ground by a team of motivated, united, and skilled individuals.',
    equipeParagraph2: 'Gathering these team members has been my priority since taking the rein of the estate in summer 2015. I valued in particular youth, as it brings desire, energy, and fearlessness, all qualities that we need to rise to the challenge my wife and I have taken up: bring Chateau de Beaumel to the top of the Cotes de Provence AOP names.',
    equipeParagraph3: 'But enthusiasm can only take projects so far, and the quartet we selected to assist us in this quest was also chosen for its rigour, professionalism and expertise. In a way, we assembled our team the way we want our wines: between strength and subtlety, we chose… both, to find the perfect balance!”',
    equipeBrigitte: 'Of Italian descent, raised in the Var region, excellent musician and graced with undisputed artistic sense, Brigitte Caille has taken up with enthusiasm the restoration of the vineyard and its estate.',
    // orders
    orderPlacedHeader: 'Your order is placed!',
    orderPlacedText: 'Congratulations. Your order and payment has been accepted.',
    emptyCartHeader: 'Your cart is empty!',
    emptyCartText: 'You\'ll need to add some items to the cart before you can checkout.',
    buttonDiscover: 'Discover',
    buttonOrder: 'Order',
    continueShopping: 'Continue shopping',
    outOfStock: 'This product is out of stock.',
    itemsInCart: 'Sub-total ({itemCount} {itemCount, plural, one {item} other {items}})',
    goToCart: 'Go to cart',
    addToCart: 'Add to cart',
    loadingToCart: 'Adding to cart...',
    seeDetails: 'See details',
    // shipping
    shippingNotice: 'Free shipping for orders above 300€. 30€ (including taxes) for orders below 300€',
    // goToCheckout
    goToCheckout: 'Go to checkout',
    subTotal: 'Sub-total',
    shippingRate: 'Shipping',
    // product
    origin: 'Origin',
    millesime: 'Vintage',
    conditionnement: 'Packaging',
    volume: 'Volume',
    // other
    cookieConsent: 'This website uses cookies to enhance the user experience.',
    openingTimes: 'Cellar open from 9h to 12h30 and 14h to 17h30 Tuesday to Saturday.',
    gpsCoordinates: 'GPS coordinates',
    termsAndConditions: 'Terms & conditions',
    promo: '-10% on all rosé bottles',
    promoTitle: 'SUMMER DEAL',
  },
  fr: {
    domaine: 'Le Domaine',
    terroir: 'Le Terroir',
    wines: 'Les Vins',
    equipe: 'L\'Equipe',
    medailles: 'Les Médailles',
    boutique: 'La Boutique',
    panier: 'Mon Panier',
    rouge: 'Les Rouges',
    Rouges: 'Les Rouges',
    rose: 'Les Rosés',
    Rosés: 'Les Rosés',
    blanc: 'Les Blancs',
    Blancs: 'Les Blancs',
    // homepage
    homeHeroTitle1: 'De l\'authenticité',
    homeHeroSubtitle1: 'Au cœur du Var, un petit coin de paradis qui cultive la passion de la vigne et du vin depuis plus d\'un siècle...',
    homeHeroTitle2: 'L\'esprit Nature',
    homeHeroSubtitle2: 'Face au Massif des Maures, un vignoble en plein renouveau, riche des plus beaux cépages de l\'AOP Côtes de Provence...',
    homeHeroTitle3: 'L\'âme Provence',
    homeHeroSubtitle3: 'Des vins qui portent en eux le soleil du Midi et la fraîcheur de la Méditerranée...',
    homeTitle1: 'Vignerons par passion',
    homeParagraph1: 'En épousant la carrière de vigneron, nous avons pimenté notre vie de quelques grains de folie, de beaucoup de passion et d’autant de plaisir. La folie ? Il en faut pour vivre en permanence à la merci des caprices du ciel et pour risquer le travail de toute une année sur quelques jours, parfois même quelques heures de vinification. La passion ? Chez nous, elle se conjugue au pluriel : l’amour du terroir, le goût du bon vin et un attachement profond pour le Midi, le pays des Côtes de Provence, cette région magnifique qui fait si bien rimer beauté, simplicité et convivialité. Le plaisir ? Celui que nous éprouvons à l’heure des vendanges ou des assemblages quand, en croquant dans un raisin, en goûtant un jus tout juste sorti des cuves, nous devinons ce que sera notre vin. Ce grain de folie, cette passion, ce plaisir, nous les avons mis en bouteille. Notre souhait le plus cher ? Que vous les retrouviez intacts à chaque fois que vous dégusterez un Château de Beaumel.',
    homeTitle2: 'Les vins du Château de Beaumel',
    homeParagraph2: 'Notre domaine signe la majorité de ses vins sous l\'Appellation Côtes de Provence. Depuis sa création en 1977, cette AOP n\'a cessé d\'affirmer une personnalité mariant convivialité et raffinement, tradition et modernité. C\'est ainsi que ses rosés font référence dans le monde entier, que ses blancs rencontrent un succès grandissant, que ses rouges gourmands étonnent et ravissent de plus en plus de palais. De belles couleurs que le Château de Beaumel entend aujourd\'hui faire briller dans vos verres...',
    homeRecompenseTitle: 'Une récompense...',
    homeRecompenseText: 'En mai 2016, Hachette, la référence des guides œnologiques, a sorti son premier guide consacré exclusivement aux rosés. Un ouvrage de référence dans lequel figure notre rosé AOP Côtes de Provence cuvée Diamant. Notre vin est gratifié d\'une étoile et honoré de ce joli commentaire : « Un rosé de grenache et de cinsault, aux jolis reflets ambrés, bien concentré sur les fruits dès l\'olfaction : pêche, mûre, litchi… En bouche, du volume, de la matière et une rondeur contrebalancée par la vivacité du fruit exotique. Un vin de repas ».',
    // domaine page
    domaineHeroTitle1: 'Sous le charme',
    domaineHeroSubtitle1: 'Noyée dans la verdure, à l\'ombre d\'arbres centenaires, une bastide du XVIIe restaurée avec soin...',
    domaineHeroTitle2: 'Un instant de paix',
    domaineHeroSubtitle2: 'Echapper aux rumeurs de la ville pour goûter à un silence à peine troublé par le chant des cigales...',
    domaineHeroTitle3: 'De la convivialité',
    domaineHeroSubtitle3: 'Ouvert toute l\'année, notre caveau de vente vous invite à découvrir l\'ensemble de nos vins. Le verre de l\'amitié...',
    domaineTitle1: 'Au cœur de la Provence',
    domaineParagraph1: 'Installé aux portes du village de Gonfaron, cerné par le Massif des Maures, le Château de Beaumel associe une grande et belle bastide du XVIIe siècle à près d’une trentaine d’hectares de vignes. ',
    domaineParagraph2: 'Cette vocation viticole remonte à plus d’un siècle. Une longue histoire qui s’est accélérée dans les années 90. Le domaine — que l’on connaît alors sous le nom de “Domaine de Beaumet”— participe alors par la qualité et la typicité de sa production à la réputation grandissante des Côtes de Provence.',
    domaineParagraph3: 'Un comble au pays du rosé : ce sont tout d’abord les blancs et les rouges qui font parler de la maison ! Ils reviennent de Paris et Mâcon, les deux plus beaux concours de France, avec de belles médailles autour du col, témoignant ainsi de la noblesse de leur terroir et du savoir-faire du vigneron. Depuis ces premiers succès, la belle réputation du domaine ne s’est jamais démentie, son palmarès ne cessant de s’étoffer. Mieux : dans les années 2000, la production a gagné encore en qualité, le propriétaire de l’époque lançant la rénovation de sa cave et la restructuration de son vignoble. Un nouvel élan que perpétuent la famille Caille, maîtres des lieux depuis 2015.',
    domaineTitle2: 'Un véritable coup de coeur',
    domaineParagraph4: 'Daniel, le premier, a nourri le rêve vigneron. Il y a quelques années, à l’aube de la soixantaine, ses racines paysannes, héritées de ses grands-parents, se rappellent à son bon souvenir. Ainsi, après la Santé et l’Environnement, deux domaines dans lesquels ce polytechnicien s’est particulièrement distingué, il se lance un nouveau défi en ajoutant une corde — la plus sensible ! —  à son arc : le Vin. Brigitte, son épouse, le suit dans cette nouvelle aventure. Elle y met toutefois une condition : pour cette Méditerranéenne 100% — par ses origines autant que par son tempérament — ce “retour à la terre” ne se conçoit que sous le soleil du Midi. Le couple se met donc en recherche d’une propriété en Provence. Leur quête passe un jour par Beaumet : c’est le coup de foudre !',
    domaineParagraph5: 'Aurait-il pu en être autrement ? Comment résister en effet à cette belle propriété, à ses murs fanés par des siècles de soleil, à ses paysages verdoyants et, par dessus-tout, au potentiel de son vignoble ? Classé pour l’essentiel en AOP Côtes de Provence, il est riche des meilleures cépages provençaux : syrah, cinsault, grenache, mourvèdre, cabernet-sauvignon, rolle… Autant de variétés qui, grâce à l’art de l’assemblage, apportent aux parfums et à l’harmonie des vins du Château de Beaumel.',
      // home wine items
    rougeDescription: 'Des vins qui s\'inscrivent dans la grande tradition des rouges provençaux : un fruité séduisant au nez, une bouche soyeuse, des tanins présents mais fondus pour un plaisir immédiat ou attendu quelques années.',
    roseDescription: 'Des robes limpides, des nez riches et gourmands, de la vivacité en bouche : les vins du Château de Beaumel portent la “patte” inimitable des fameux rosés de Provence.',
    blancDescription: 'De belles expressions du rolle, l\'un des cépages rois du vignoble provençal. Un nez suave et une bouche dense pour des vins qui affirment fièrement leur caractère à table comme à l\'apéritif.',
    // terroir page
    terroirHeroTitle1: 'Le goût du terroir',
    terroirHeroSubtitle1: 'Un vignoble d\'une trentaine d\'hectares cultivés avec beaucoup de rigueur et de naturel...',
    terroirHeroTitle2: 'Les racines du bon vin',
    terroirHeroSubtitle2: 'Une terre caillouteuse et profonde, un sol argilo-calcaire dans lequel la vigne puise tout ce dont elle a besoin...',
    terroirHeroTitle3: 'L\'accent du sud',
    terroirHeroSubtitle3: 'Du grenache, du cinsault, de la syrah, du rolle pour des vins typiquement méditerranéens...',
    terroirTitle1: 'De la terre nait le vin',
    terroirParagraph1: 'Tous les vignerons vous le diront : quel que soit leur savoir-faire, quelque soit leur matériel, ils ne peuvent produire un bon vin que si la Nature les a gâtés d’un beau raisin. Or, plusieurs facteurs pèsent sur la qualité de la vendange. La météo, bien sûr ! C’est le fameux effet “millésime”. Chez nous, il se joue sur quelques nuances. Si la Provence n’échappe pas aux caprices du ciel, des gels tardifs à la canicule, elle est en effet moins sensible que d’autres aux variations climatiques. Ce n’est pas pour rien que la région attire des millions de touristes : ils savent y trouver chaque année un grand ciel bleu et un soleil généreux !',
    terroirTitle2: 'L\'influence du sol',
    terroirParagraph2: 'Tout aussi déterminant que le climat : la nature du sol. Les vignes du Château de Beaumel sont plantées sur des plaines argilo-calcaires. Une double influence… L’argile maintien ce qu’il faut d’humidité dans le sous-sol pour que la vigne poursuive son développement jusque dans les périodes les plus chaudes de l’été. Elle dynamise également la production de tanins, l’un des composants les plus précieux du vin. Dès lors qu’elle est travaillée, voire transcendée par le maître de chais, cette substance naturelle donne en effet du caractère et de la structure aux vins rouges. Quant au calcaire, il apporte de l’acidité aux baies et développe leurs parfums. Idéal pour faire des rosés vifs et gourmands.',
    terroirParagraph3: 'Autre particularité des terres de Beaumel : elles sont caillouteuses. Un atout indéniable ! La présence de pierres dans la terre favorise en effet l’aération et le drainage du sol, la libération de chaleur aux heures les plus fraîches (un phénomène qui, en été, permet une maturation plus régulière) et l’enracinement profond des ceps. Ces derniers peuvent ainsi profiter pleinement des oligo-éléments enfouis dans les couches inférieures du sous-sol. A l’heure des vendanges, dès lors que le soleil a fait son œuvre, le potentiel aromatique des baies n’en est que plus grand. Surtout que les méthodes de culture mises en place par notre équipe, davantage tournée vers le naturel que vers la chimie, permettent de tirer la quintessence de notre terroir !',
    terroirTitle3: 'La qualité des vignes',
    terroirParagraph4: 'Le goût du vin dépend également des vignes qui en sont à l’origine. Leur âge est important. En effet, à vingt ans passés, les ceps donnent moins mais donnent mieux, apportant de la matière et du parfum aux vins. Ainsi, tout en rajeunissant sans cesse son vignoble pour en assurer la vitalité, le Château de Beaumel veille sur ses vieux serviteurs : des grenache trentenaires, joyaux de la cuvée Diamant, et des cabernet-sauvignon de dix ans leurs aînés qui font la beauté de la fameuse Grande Réserve, le fleuron du domaine.',
    terroirParagraph5: 'La variété des raisins a également son importance. L’encépagement du domaine est ainsi diversifié pour permettre la production de rouges, rosés et blancs de qualité. On y trouve  du grenache et du cinsault, ces deux inséparables qui, mariés à l’heure de la vinification (on parle alors d’assemblage), font l’harmonie inimitable du rosé de Provence. Les blancs, eux, sont faits de sémillon et, plus encore, de rolle. Celui que l’on appelle aussi vermentino a trouvé de ce côté-ci de la Méditerranée son terroir d’excellence. Enfin, les rouges se nourrissent de syrah et de cabernet-sauvignon. Pour les AOP Côtes de Provence, du moins ! Car Beaumel cultive une petite “coquetterie” : il compte dans ses rangs du… merlot. Cette star du vignoble bordelais se plaît sous le soleil du Midi. En témoigne notre vin de pays 100% merlot, une cuvée qui séduit de longue date par son fruité exubérant. et sa belle finesse.',
    // equipe page
    equipeTitle1: 'Le travail de toute une équipe',
    equipeParagraph1: '"L\'expérience a du bon ! La mienne m\'a ainsi appris que la réussite d\'une entreprise, quelle qu\'elle soit, passe toujours par un collectif fort. Bien sûr, il faut un leader, quelqu\'un qui impulse une dynamique, qui détermine les objectifs et met en place les moyens nécessaires à les atteindre. Mais cet élan s\'essoufle vite s\'il n\'est pas relayé sur le terrain par un groupe motivé, soudé et, par dessus tout, compétent.',
    equipeParagraph2: 'La création de cette équipe a été ma priorité lors de mon arrivée aux commandes du domaine, à l\'été 2015. Je l\'ai voulue pleine de jeunesse. On prête en effet aux jeunes gens une envie, une énergie, une audace au-dessus de la moyenne. Des qualités dont nous avons bien besoin pour relever le challenge que nous nous sommes fixé, mon épouse et moi : hisser notre Château parmi les références de l\'AOP Côtes de Provence.',
    equipeParagraph3: 'Mais l\'enthousiasme ne pouvant pas tout, le quatuor qui nous accompagne dans cette belle aventure brille aussi, vous pouvez m\'en croire, par sa rigueur, son professionnalisme et son expertise. Ainsi, nous avons construit notre équipe comme un bon vin : entre la puissance et la finesse, nous avons choisi de... ne pas choisir pour trouver le parfait équilibre !"',
    equipeBrigitte: 'D’origine italienne et ayant passé son enfance dans le Var, musicienne d’excellence et douée d’un sens artistique reconnue, Brigitte Caille s’est attachée avec passion à la réhabilitation de ce domaine et de son patrimoine immobilier.',
    // orders
    orderPlacedHeader: 'Votre commande a été placée !',
    orderPlacedText: 'Félicitations. Votre commande et votre paiement ont été acceptés.',
    emptyCartHeader: 'Votre panier est vide !',
    emptyCartText: 'Veuillez ajouter des produits à votre panier afin de compléter la commande.',
    continueShopping: 'Retourner à la boutique',
    outOfStock: 'Produit épuisé.',
    itemsInCart: 'Sous-total ({itemCount} {itemCount, plural, one {article} other {articles}})',
    goToCart: 'Voir mon panier',
    addToCart: 'Ajouter au panier',
    loadingToCart: 'En cours...',
    seeDetails: 'Voir détails',
    // boutons
    buttonDiscover: 'Découvrir',
    buttonOrder: 'Commander',
    // shipping
    shippingNotice: 'Livraison offerte à partir de 300€ de commande, sinon participation aux frais d\'expeditions de 30€ TTC',
    // checkout
    goToCheckout: 'Valider la commande',
    origin: 'Origine',
    millesime: 'Millésime',
    conditionnement: 'Conditionnement',
    volume: 'Volume',
    subTotal: 'Sous-total',
    shippingRate: 'Frais de livraison',
    // other
    cookieConsent: 'Ce site utilise des cookie pour améliorer votre experience utilisateur. En poursuivant votre navigation sur ce site, vous acceptez l’utilisation de cookies.',
    openingTimes: 'Cave ouverte de 9h à 12h30 et de 14h à 17h30 du mardi au samedi.',
    gpsCoordinates: 'Coordonnées GPS',
    termsAndConditions: 'Conditions génerales de vente',
    promo: '-10% sur toutes les bouteilles de rosés',
    promoTitle: 'PROMOTION D’ÉTÉ'
    },
}

export { messages }
