import React, { Component } from 'react'
import { Link } from 'gatsby'
import { Menu } from 'semantic-ui-react'
import { slide as Slide } from 'react-burger-menu'
import { FormattedMessage, intlShape } from 'react-intl'
import ShoppingCartIcon from './shopping-cart-icon'
import Logo from './logo'
import SelectLanguage from './select-language'
import { Media } from '../layout'

class MobileMenu extends Component {
  render() {
    return (
      <Media lessThan="tablet">
        <Menu
          size="huge"
          borderless
          pointing
          inverted
          secondary
          style={{
            backgroundColor: '#8f1136',
            borderRadius: 'none',
            marginBottom: 0,
            borderWidth: 0,
          }}
        >
          <Menu.Item as={Link} to={`/`} header style={{ padding: 10 }}>
            <Logo />
          </Menu.Item>

          <div>
            <Slide right>
              <Menu.Item as={Link} to={`/domaine/`}>
                <FormattedMessage id="domaine" />
              </Menu.Item>

              <Menu.Item as={Link} to={`/terroir/`}>
                <FormattedMessage id="terroir" />
              </Menu.Item>

              <Menu.Item as={Link} to={`/equipe/`}>
                <FormattedMessage id="equipe" />
              </Menu.Item>

              <Menu.Item as={Link} to={`/medailles/`}>
                <FormattedMessage id="medailles" />
              </Menu.Item>

              <Menu.Item as={Link} to={`/shop/`}>
                <FormattedMessage id="boutique" />
              </Menu.Item>

              <Menu.Item>
                <ShoppingCartIcon />
              </Menu.Item>

              <Menu.Item>
                <SelectLanguage {...this.props} />
              </Menu.Item>
            </Slide>
          </div>
        </Menu>
      </Media>
    )
  }
}

export default MobileMenu
