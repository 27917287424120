import React, { Component } from 'react'
import { Link, withPrefix } from 'gatsby'
import { Menu, Container, Segment } from 'semantic-ui-react'
import { FormattedMessage } from 'react-intl'
import ShoppingCartIcon from './shopping-cart-icon'
import Logo from './logo'
import SelectLanguage from './select-language'
import { Media } from '../layout'

class DesktopMenu extends Component {
  render() {
    const activeItem = this.props.location && this.props.location.pathname
    return (
      <Media greaterThanOrEqual="tablet">
        <Menu
          size="huge"
          borderless
          pointing
          inverted
          secondary
          style={{
            backgroundColor: '#8f1136',
            borderRadius: 'none',
            marginBottom: 0,
          }}
        >
          <Container>
            <Menu.Item
              active={activeItem === withPrefix('/')}
              as={Link}
              to={`/`}
              header
              style={{ padding: 10 }}
            >
              <Logo />
            </Menu.Item>
            <Menu.Menu position="right">
              <Menu.Item
                as={Link}
                to={`/domaine/`}
                active={activeItem === withPrefix(`/domaine/`)}
              >
                <FormattedMessage id="domaine" />
              </Menu.Item>

              <Menu.Item
                as={Link}
                to={`/terroir/`}
                active={activeItem === withPrefix(`/terroir/`)}
              >
                <FormattedMessage id="terroir" />
              </Menu.Item>

              <Menu.Item
                as={Link}
                to={`/equipe/`}
                active={activeItem === withPrefix(`/equipe/`)}
              >
                <FormattedMessage id="equipe" />
              </Menu.Item>

              <Menu.Item
                as={Link}
                to={`/medailles/`}
                active={activeItem === withPrefix(`/medailles/`)}
              >
                <FormattedMessage id="medailles" />
              </Menu.Item>

              <Menu.Item
                as={Link}
                to={`/shop/`}
                active={activeItem === withPrefix(`/shop/`)}
              >
                <FormattedMessage id="boutique" />
              </Menu.Item>

              <Menu.Item>
                <ShoppingCartIcon />
              </Menu.Item>
              <Menu.Item>
                <SelectLanguage {...this.props} />
              </Menu.Item>
            </Menu.Menu>
          </Container>
        </Menu>
      </Media>
    )
  }
}

export default DesktopMenu
