import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Header from './Header'
import { IntlProvider, FormattedMessage } from 'react-intl'
import CookieConsent from 'react-cookie-consent'
import { messages } from '../data/translations'
import { ContextProvider } from './Context'
import Footer from './Footer'
import '../semantic/dist/semantic.min.css'
import './layout.css'
import { createMedia } from '@artsy/fresnel'

export const Head = () => (
  <>
    <title>Chateau de Beaumel</title>
  </>
)

const AppMedia = createMedia({
  breakpoints: {
    mobile: 320,
    tablet: 768,
    computer: 992,
    largeScreen: 1200,
    widescreen: 1920,
  },
})

const mediaStyles = AppMedia.createMediaStyle()
const { Media, MediaContextProvider } = AppMedia

export { Media }

class Layout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      locale: 'fr',
    }
    this._updateLocale = this._updateLocale.bind(this)
  }

  _updateLocale(locale) {
    this.setState({
      locale,
    })
  }

  render() {
    const { children, location } = this.props
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <div>
            <ContextProvider>
              <IntlProvider
                locale={this.state.locale}
                messages={messages[this.state.locale]}
              >
                <MediaContextProvider>
                  <div>
                    <Header
                      siteTitle={data.site.siteMetadata.title}
                      locale={this.state.locale}
                      updateLocale={this._updateLocale}
                      location={location}
                    />
                    <div style={{ minHeight: 'calc(100vh - 450px)' }}>
                      {children}
                    </div>
                    <Footer />
                    <CookieConsent
                      location="bottom"
                      buttonText="Accepter"
                      cookieName="cookie-consent"
                      expires={150}
                      style={{
                        fontSize: '1rem',
                      }}
                      buttonStyle={{
                        backgroundColor: '#8f1136',
                        borderRadius: '2px',
                        color: 'white',
                        fontSize: '1rem',
                        padding: '10px 20px',
                        cursor: 'pointer',
                      }}
                    >
                      <p>
                        <FormattedMessage id="cookieConsent" />
                      </p>
                    </CookieConsent>
                  </div>
                </MediaContextProvider>
              </IntlProvider>
            </ContextProvider>
          </div>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
